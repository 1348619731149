<template>
  <div class='qts-tickets'>
    <div class="wrapper">
      <div class="header">
        <span class="selected-date">{{ selectedDateFromStore | moment('YYYY.MM.DD.') }}</span>
        <span class="title">{{ $t('general.quickTicketSelector.ticketSelector.title') }}</span>
        <span v-if="facilityDescriptionKey" class="subtitle" v-html="$t(facilityDescriptionKey)" />
      </div>

      <transition
        v-if="tickets === undefined"
        mode="out-in"
        enter-active-class="animate__animated animate__fadeIn animate__faster"
        leave-active-class="animate__animated animate__fadeOut animate__faster"
      >
        <div  class="loader"><font-awesome-icon :icon="['fa', 'spinner']" /></div>
      </transition>

      <div v-else-if="tickets !== undefined && tickets.length > 0 && !errorToast.isVisible && !limitErrorToast.isVisible" class="tickets">
        <ticket-row
          v-for="(item, i) in tickets" :key="i"
          :ref="`ticketRow-${i}`"
          :ticket="item"
          :isDateSelected="true"
          :isCartBtnDisabled="true"
          @quantityChanged="handleInputChange(i, item.cikk_id, ...arguments)"
        />
      </div>

      <error-toast
        :isVisible="errorToast.isVisible"
        :text="$t(errorToast.text)"
      />
      <error-toast
        :isVisible="limitErrorToast.isVisible"
        :text="$t(limitErrorToast.text, {amount: cartLimit})"
      />

      <base-button
        v-if="tickets !== undefined && tickets.length > 0 && !errorToast.isVisible && !limitErrorToast.isVisible"
        :isDisabled="tickets && tickets.length === 0"
        :isSecondary="true"
        :isLoading="isBtnLoading"
        :text="$t('general.quickTicketSelector.ticketSelector.button.submit')"
        @clicked="handleBtnClick"
      />
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapActions, mapGetters } from 'vuex';
import facilities from '@/components/shared/mixins/facilities';

import TicketRow from '@/components/shared/modules/tickets/TicketRow';
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';

const ProductNumberAlertModal = () => import('@/components/shared/modules/modals/ProductNumberAlertModal');

export default {
  name: 'QTSTickets',
  mixins: [facilities],
  props: {
    groupId: {
      type: String,
      required: false,
    },
    enumKey: {
      type: String,
      required: false,
    },
  },
  components: {
    TicketRow,
    BaseButton,
    'error-toast': () => import('@/components/shared/elements/toasts/ErrorToast'),
  },
  data: () => ({
    tickets: undefined,
    isBtnLoading: false,
    items: [],
    errorToast: {
      isVisible: false,
      text: 'general.quickTicketSelector.ticketSelector.emptyState',
    },
    limitErrorToast: {
      isVisible: false,
      text: 'general.cartModule.cartLimit',
    },
    cartLimit: undefined,
  }),
  created() {
    this.errorToast.isVisible = false;
    this.getCartLimit().then((response) => {
      this.cartLimit = response.data;
    });
    if (this.groupId !== undefined) {
      this.getCikks({
        cikkcsoportId: this.groupId,
        selectedDate: {
          date: this.$moment(this.selectedDateFromStore).format('YYYY-MM-DD'),
        },
      }).then((resp) => {
        this.tickets = resp;
      });
    } else {
      this.tickets = [];
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCart: 'cart/getCurrentCart',
      getLocalCikkcsoports: 'cikkcsoports/getLocalCikkcsoports',
    }),
    selectedDateFromStore() {
      return store.getters.getSelectedDate;
    },
    facilityDescriptionKey() {
      // eslint-disable-next-line eqeqeq
      const cikkcsoport = this.getLocalCikkcsoports.find((item) => item.cikkcsop_id == this.$FACILITIES.ID_TO_KEY[this.enumKey]);

      if (cikkcsoport && cikkcsoport.facilityDescription) {
        if (cikkcsoport.facilityDescription.description && cikkcsoport.facilityDescription.externalLink) {
          return `${cikkcsoport.facilityDescription.description} <br><a target='_blank' href="${cikkcsoport.facilityDescription.externalLink}">${cikkcsoport.facilityDescription.externalLink}</a>`;
        }
        return cikkcsoport.facilityDescription.description;
      }
      return undefined;
    },
  },
  watch: {
    tickets(val) {
      if (val && val.length === 0) {
        this.errorToast.isVisible = true;
        this.errorToast.text = this.$t('general.quickTicketSelector.ticketSelector.emptyState');
      }
    },
  },
  methods: {
    ...mapActions({
      getCikks: 'cikks/getCikks',
      addCartItem: 'cart/addCartItem',
      createCart: 'cart/createCart',
      getCart: 'cart/getCart',
      checkCartLimit: 'cart/checkCartLimit',
      getCartLimit: 'settings/getCartLimit',
    }),
    addItemsToCart() {
      if (this.$FACILITIES.FACILITIES_WITH_WARNING.includes(parseInt(this.groupId, 10))) {
        this.$eventBus.showFacilityWarningModal();
      }

      const promises = [];
      const amountSum = this.items.map((item) => item.amount).reduce((a, b) => a + b, 0);

      this.checkCartLimit({ cartId: store.getters.cartId, amount: amountSum }).then((resp) => {
        if (resp) {
          // eslint-disable-next-line no-unreachable
          for (let i = 0; i < this.items.length; i += 1) {
            promises.push(
              this.addCartItem({
                cartId: store.getters.cartId,
                requestObj: this.items[i],
                store: false,
              }).catch((err) => {
                console.log(err);
                this.errorToast.isVisible = true;
                this.errorToast.text = err.data.message;
              }),
            );
          }

          // eslint-disable-next-line no-unreachable
          Promise.all(promises).then(() => {
            this.isBtnLoading = false;
            for (let i = 0; i < this.items.length; i += 1) {
              this.$refs[`ticketRow-${this.items[i].rowIndex}`][0].quantityNumber = 0;
            }

            this.getCart(store.getters.cartId).then(() => {
              this.$eventBus.itemsAddedFromQTS();
            });
          });
        // eslint-disable-next-line no-empty
        } else {
          this.limitErrorToast.isVisible = true;
        }
      });
    },
    showAlertModal() {
      this.$eventBus.showModal({
        bind: {
          is: ProductNumberAlertModal,
          titleText: this.$t('general.alerts.productNumber.title'),
          descriptionText: this.$t('general.alerts.productNumber.description'),
          primaryBtnText: this.$t('general.alerts.productNumber.btnText'),
          modalTitleStyle: { margin: '20px 0 0 0' },
          closeable: true,
        },
        on: {
          primaryButtonClicked: () => {
            this.$eventBus.closeModal();
          },
        },
      });
    },
    handleBtnClick() {
      if (this.items.length === 0) {
        this.showAlertModal();
      } else {
        this.isBtnLoading = true;

        if (store.getters.cartId === undefined) {
          this.createCart().then(() => {
            this.addItemsToCart();
          });
        } else {
          this.addItemsToCart();
        }
      }
    },
    handleInputChange(rowIndex, id, quantity) {
      const searchIndex = this.items.findIndex((item) => item.cikkId === id);

      if (searchIndex > -1) {
        if (quantity === 0) {
          this.items.splice(searchIndex, 1);
        } else {
          this.items[searchIndex].amount = quantity;
        }
      } else if (quantity > 0) {
        this.items.push({
          rowIndex,
          cikkId: id,
          amount: quantity,
          date: this.$moment(this.selectedDateFromStore).format('YYYY-MM-DD'),
        });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@keyframes loaderAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.wrapper {
  position: relative;
  min-height: 100%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  font-size: 18px;

  svg {
    animation: loaderAnimation 1.6s infinite;
  }
}

.header {
  .selected-date,
  .title {
    display: block;
    color: $primaryBlue;
    font-weight: 700;
    line-height: 1.2;
  }

  .title {
    font-size: 20px;
    text-transform: uppercase;
  }

  .selected-date {
    font-size: 15px;
  }

  .subtitle {
    display: block;
    font-size: 12px;
    font-weight: 500;

    &::v-deep {
      br {
        content: ' ';
        padding-right: 2px;
      }

      a {
        padding-left: 5px;
        overflow-wrap: break-word;
      }
    }
  }
}

.ticket-row {
  padding: 2px 0;
  grid-template-columns: auto 1fr 0.3fr 0.3fr;

  &::v-deep {
    .info-col {
      padding-top: 0;
      margin-top: 0;
    }
  }

  @media screen and (max-width: $breakpointDownSm) {
    grid-template-columns: auto 1fr 0.5fr;
    grid-template-rows: auto;
    row-gap: 0;
    margin: 0 0 10px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    grid-template-columns: auto 1fr 0.5fr;
    grid-template-rows: 1fr 1fr;
  }

  &::v-deep {
    .price-col {
      text-align: right;
    }

    .name-col {
      @media screen and (max-width: $breakpointDownXXs) {
        padding-right: 5px;
      }
    }

    .quantity-input-col {
      padding-right: 0;

      @media screen and (max-width: $breakpointDownSm) {
        grid-area: 1 / 4 / 2 / 5;
        margin-right: 0;
        margin-left: 25px;
      }

      @media screen and (max-width: $breakpointDownXs) {
        grid-area: 2 / 1 / 3 / 5;
      }
    }

    .price-col {
      @media screen and (max-width: $breakpointDownXs) {
        grid-area: 1 / 3 / 2 / 5;
      }
    }
  }
}

.tickets {
  margin: 25px 0 10px;
}

.error-toast.visible {
  margin: 40px 0;
}
</style>
